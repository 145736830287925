import {
  createStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { People } from "@material-ui/icons";
import * as React from "react";
import { Link } from "react-router-dom";
import ShareGroupDialog from "../ShareGroupDialog";

const styles = createStyles({
  listText: {
    padding: 0,
  },
  shareIcon: {
    margin: 0,
  },
});

type Props = {
  to?: string;
  count?: string;
  shared?: boolean;
  selected: boolean;
  groupId?: string;
  icon: React.ReactElement<any>;
  text: string;
  onClick: () => void;
} & WithStyles<typeof styles>;

interface State {
  shared?: boolean;
  groupId?: string;
  createOpen: boolean;
}

class DrawerLink extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      createOpen: false,
      groupId: this.props.groupId,
      shared: this.props.shared,
    };
  }

  public handleOpen = () => {
    this.setState({ createOpen: true });
  }

  public handleClose = () => {
    this.setState({ createOpen: false });
  }

  public handleSave = (newId: string) => {
    this.setState({ createOpen: false, shared: true, groupId: newId });
    this.props.onClick();
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.shared !== this.props.shared) {
      this.setState({ shared: this.props.shared });
    }
  }

  public render() {
    const { classes, icon, text, to, selected, onClick, count } = this.props;
    const { shared, groupId, createOpen } = this.state;

    const path = to ? to : `/group/${groupId}`;
    const fixed = to || shared;

    return (
      <React.Fragment>
        <ListItem
          selected={selected}
          onClick={fixed ? onClick : this.handleOpen}
          dense={true}
          button={true}
          component={
            fixed ? ({ innerRef, ...props }) => <Link {...props} to={path} /> : undefined}
        >
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText
            className={classes.listText}
            primary={text + (count === undefined ? "" : ` (${count})`)}
          />
          {shared && (
            <ListItemIcon className={classes.shareIcon}>
              <People />
            </ListItemIcon>
          )}
        </ListItem>
        {!fixed && (
          <ShareGroupDialog
            groupName={text}
            labelId={groupId}
            open={createOpen}
            onClose={this.handleClose}
            onSave={this.handleSave}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DrawerLink);
