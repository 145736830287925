import {
  CircularProgress,
  createStyles,
  Fab,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import * as React from "react";
import { Redirect } from "react-router-dom";
import AddUserDialog from "../components/Group/AddUserDialog";
import EditUserDialog from "../components/Group/EditUserDialog";
import GroupMenu from "../components/Group/GroupMenu";
import ShareUser from "../components/Group/ShareUser";
import SyncIcon from "../components/Group/SyncIcon";
import api from "../services/api";

const styles = (theme: Theme) =>
  createStyles({
    fab: {
      bottom: theme.spacing.unit * 2,
      position: "absolute",
      right: theme.spacing.unit * 2,
    },
    loader: {
      ...theme.mixins.gutters(),
      display: "flex",
      justifyContent: "center",
    },
    root: {
      display: "flex",
      ...theme.mixins.gutters(),
    },
    title: {
      flexGrow: 1,
    },
  });

type Props = {
  match: any;
  isAdmin: boolean;
} & WithStyles<typeof styles>;

interface State {
  createUserDialogOpen: boolean;
  editUserDialogOpen: boolean;
  group?: any;
  groupId: string;
  loading: boolean;
  selectedUser?: any;
  moreAnchor?: HTMLElement;
  checkedUsers: number[];
}

class Group extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const groupId = props.match.params.id;

    this.state = {
      checkedUsers: [],
      createUserDialogOpen: false,
      editUserDialogOpen: false,
      groupId,
      loading: true,
    };
  }

  public fetchGroup = async () => {
    const { groupId } = this.state;
    await api
      .get(`/group/${groupId}`)
      .then((group) => {
        this.setState({
          createUserDialogOpen: false,
          editUserDialogOpen: false,
          group,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          createUserDialogOpen: false,
          editUserDialogOpen: false,
          group: undefined,
          loading: false,
        });
      });
  };

  public handleDialogClose = () => {
    this.setState({
      createUserDialogOpen: false,
      editUserDialogOpen: false,
    });
  };

  public handleClick = (user: any) => {
    this.setState({ editUserDialogOpen: true, selectedUser: user });
  };

  public handleFab = () => {
    this.setState({ createUserDialogOpen: true });
  };

  public handleCheck = (id: number) => {
    const index = this.state.checkedUsers.indexOf(id);
    if (index >= 0) {
      this.setState((prevState) => {
        prevState.checkedUsers.splice(index, 1);
        return { checkedUsers: prevState.checkedUsers };
      });
    } else {
      this.setState((prevState) => {
        return { checkedUsers: [...prevState.checkedUsers, id] };
      });
    }
  };

  public handleChangeUser = (user: any) => {
    this.setState({
      selectedUser: user,
    });
  };

  public componentDidMount() {
    if (this.state.groupId) {
      this.fetchGroup();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const oldGroupId = prevProps.match.params.id;
    const groupId = this.props.match.params.id;

    if (oldGroupId !== groupId) {
      this.setState({ loading: true, groupId }, () => {
        this.fetchGroup();
      });
    }
  }

  public render() {
    const { loading, group, groupId, checkedUsers } = this.state;
    const { classes, isAdmin } = this.props;

    return (
      <React.Fragment>
        {loading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : group ? (
          <React.Fragment>
            <div className={classes.root}>
              <Typography className={classes.title} variant="subtitle1">
                {group.label.formattedName}
                {" / "}
                {group.name}
              </Typography>

              <SyncIcon
                lastSync={group.lastSync}
                status={group.status}
                onClick={this.fetchGroup}
              />
              <GroupMenu
                groupName={group.name}
                groupId={groupId}
                selectedUsers={checkedUsers}
                reload={this.fetchGroup}
              />
            </div>
            <Grid container={true} className={classes.root}>
              {group.members.map((member: any) => {
                return (
                  <ShareUser
                    onCheck={this.handleCheck}
                    checked={checkedUsers.indexOf(parseInt(member.id, 10)) >= 0}
                    onClick={this.handleClick}
                    user={member}
                    key={member.id}
                  />
                );
              })}
            </Grid>

            <Fab
              aria-label="Add"
              className={classes.fab}
              color="secondary"
              onClick={this.handleFab}
            >
              <Add />
            </Fab>

            <EditUserDialog
              changeUser={this.handleChangeUser}
              onClose={this.handleDialogClose}
              onSave={this.fetchGroup}
              open={this.state.editUserDialogOpen}
              user={this.state.selectedUser}
              groupId={groupId}
            />
            <AddUserDialog
              groupName={group.name}
              groupId={groupId}
              onClose={this.handleDialogClose}
              onSave={this.fetchGroup}
              open={this.state.createUserDialogOpen}
              isAdmin={isAdmin}
            />
          </React.Fragment>
        ) : (
          <Redirect to="/" />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Group);
