import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import api from "../../services/api";
import Autosuggest from "../Autosuggest";
import LoadingButton from "../LoadingButton";
import { notify } from "../Snackbar";

const styles = (_: Theme) =>
  createStyles({
    input: { width: "100%" },
    groupButton: { marginRight: "auto" },
  });

interface Props {
  groupId: string;
  groupName: string;
  onClose: () => void;
  onSave: () => void;
  open: boolean;
  isAdmin: boolean;
}
type PropsType = Props & WithStyles<typeof styles>;

interface State {
  groupError: boolean;
  groupName?: string;
  myContacts: boolean;
  role?: string;
  user?: string;
  userId?: string;
  userType?: string;
}

class AddUserDialog extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      groupError: false,
      groupName: this.props.groupName,
      myContacts: true,
      role: "",
      user: "",
      userId: "",
      userType: "",
    };
  }

  public render() {
    const { open, onClose, classes, isAdmin } = this.props;
    const { role, user, groupName, groupError, myContacts } = this.state;

    return (
      <Dialog open={open} onClose={onClose} onExited={this.reset}>
        <DialogTitle>Create Share</DialogTitle>
        <DialogContent>
          <Autosuggest
            className={classes.input}
            label="User / Group"
            margin="dense"
            name="user"
            onChange={this.handleChange}
            onSelect={(userType: string) => {
              this.setState({ userType });
            }}
            value={user!}
          />
          <TextField
            error={groupError}
            className={classes.input}
            label="Label Name"
            margin="dense"
            name="groupName"
            onChange={this.handleChange}
            value={groupName}
          />
          <TextField
            className={classes.input}
            label="Role"
            margin="dense"
            name="role"
            onChange={this.handleChange}
            select={true}
            value={role}
          >
            <MenuItem value={"READER"}>Reader</MenuItem>
            <MenuItem value={"WRITER"}>Writer</MenuItem>
            <MenuItem value={"OWNER"}>Owner</MenuItem>
            {isAdmin && <MenuItem value={"DOMAIN"}>Domain</MenuItem>}
          </TextField>
          <FormControl margin={"dense"} className={classes.input}>
            <InputLabel filled={true} shrink={true}>
              My Contacts
            </InputLabel>
            <div>
              <FormControlLabel
                style={{ marginTop: "16px" }}
                label={myContacts ? "Add to my contacts" : "Don't add to my contacts"}
                control={<Switch checked={myContacts} />}
                onChange={this.handleMyContacts}
              />
            </div>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton onClick={this.handleSave}>Save</LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }

  private reset = () => {
    this.setState({
      groupName: this.props.groupName,
      role: "",
      user: "",
      userId: "",
    });
  };

  private handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  private handleMyContacts = (_: any, checked: boolean) => {
    this.setState({ myContacts: checked });
  };

  private handleSave = async () => {
    await api
      .post(`/group/${this.props.groupId}/member`, this.state)
      .then((response) => {
        try {
          if (response && Array.isArray(response) && response.length > 0) {
            notify(
              "warning",
              "not all users could be created. please check your console",
            );
            console.log(response);
          }
        } catch (e) {}
        this.props.onSave();
      })
      .catch((error) => {
        notify("error", error.response.data.error);
        const status = error.response.status;
        if (status === 409) {
          this.setState({ groupError: true });
        }
      });
  };
}

export default withStyles(styles)(AddUserDialog);
