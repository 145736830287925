import {
  Checkbox,
  createStyles,
  Grid,
  Hidden,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { People, Person } from "@material-ui/icons";
import * as React from "react";

const styles = createStyles({
  mail: {
    width: "40%",
  },
  name: {
    flexGrow: 1,
  },
  role: {
    width: "10%",
  },
  root: {
    display: "flex",
    width: "100%",
  },
  row: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.08)",
    },
    alignItems: "center",
    display: "flex",
    height: "50px",
    padding: "16px",
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  typeIcon: {
    marginRight: "6px",
    marginLeft: "-12px",
  },
});

type Props = {
  onClick: (user: any) => void;
  onCheck: (id: number) => void;
  user: any;
  checked: boolean;
} & WithStyles<typeof styles>;

class ShareUser extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public handleClick = () => {
    this.props.onClick(this.props.user);
  };

  public handleCheck = () => {
    this.props.onCheck(parseInt(this.props.user.id, 10));
  };

  public render() {
    const { classes, user, checked } = this.props;
    return (
      <div className={classes.root}>
        <Checkbox checked={checked} onClick={this.handleCheck} />
        <Grid onClick={this.handleClick} className={classes.row} item={true} xs={12}>
          {user.googleContactGroupId === "group" ? (
            <People className={classes.typeIcon} />
          ) : (
            <Person className={classes.typeIcon} />
          )}
          <Typography className={classes.name}>{user.name}</Typography>
          <Hidden className={classes.mail} implementation="css" smDown={true}>
            <Typography className={classes.text}>{user.email}</Typography>
          </Hidden>
          <Typography className={classes.role}>{user.role}</Typography>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ShareUser);
