import {
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";

const styles = (theme: Theme) =>
  createStyles({
    card: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      maxWidth: "500px",
      padding: theme.spacing.unit * 2,
    },
    cardImage: {
      width: "200px",
    },
    spacer: {
      height: `calc(100vh - 64px - ${theme.spacing.unit * 4}px)`,
      [theme.breakpoints.down(600)]: {
        height: `calc(100vh - 48px - ${theme.spacing.unit * 4}px)`,
      },
    },
    text: {
      marginTop: theme.spacing.unit * 2,
    },
  });

type Props = {} & WithStyles<typeof styles>;

class Support extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { classes } = this.props;
    return (
      <Grid container={true} justify="center" className={classes.spacer}>
        <div className={classes.card}>
          <Typography align="justify">
            When you need advanced technical help for issues that can’t be resolved with
            our FAQ (contactify.wabion.com), you can always count on our friendly support
            agents. We’re here to help!
          </Typography>
          <Typography className={classes.text} align="justify">
            Please write us an email to{" "}
            <a href="mailto:contactify@wabion.com">contactify@wabion.com</a> and one of
            our supporters will get back to you.
          </Typography>
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(Support);
