import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { Redirect } from "react-router";
import api from "../services/api";
import LoadingButton from "./LoadingButton";

const styles = () => createStyles({});

interface Props {
  onClose: () => void;
  onSave: () => void;
  open: boolean;
}

type PropsType = Props & WithStyles<typeof styles>;

interface State {
  groupName: string;
  redirect: boolean;
  groupId?: string;
  checked: boolean;
}

class AddNewGroup extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);
    this.state = { groupName: "", redirect: false, checked: true };
  }

  public handleSave = async () => {
    const label = await api.post("/label", {
      groupName: this.state.groupName,
    });
    const labelId = label.resourceName.split("/")[1];

    const group = await api.post("/group", {
      groupName: this.state.groupName,
      labelId,
    });

    this.setState({ redirect: true, groupId: group.id, groupName: "" });
    this.props.onSave();
  }

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ groupName: event.target.value });
  }

  public handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ checked: event.target.checked });
  }

  public render() {
    const { open, onClose } = this.props;
    const { groupName, redirect, groupId, checked } = this.state;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Add New Group</DialogTitle>
        <DialogContent>
          <TextField label="Group Name" value={groupName} onChange={this.handleChange} />
          <div>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={this.handleCheck} />}
              label="Share Group"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton onClick={this.handleSave}>Create</LoadingButton>
        </DialogActions>
        {redirect && <Redirect to={`/group/${groupId}`} />}
      </Dialog>
    );
  }
}

export default withStyles(styles)(AddNewGroup);
