import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import * as React from "react";
import { Redirect } from "react-router";
import api from "../../services/api";
import LoadingButton from "../LoadingButton";

const styles = (_: Theme) => createStyles({});

type Props = {
  groupId: string;
  groupName: string;
  selectedUsers: number[];
  reload: () => void;
} & WithStyles<typeof styles>;

interface State {
  anchorEl?: HTMLElement;
  deleteGroupOpen: boolean;
  deleteMembersOpen: boolean;
  groupName: string;
  redirect?: boolean;
  renameGroupOpen: boolean;
}

class GroupMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      deleteGroupOpen: false,
      deleteMembersOpen: false,
      groupName: this.props.groupName,
      renameGroupOpen: false,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.groupName !== this.props.groupName) {
      this.setState({ groupName: this.props.groupName });
    }
  }

  public render() {
    const { selectedUsers } = this.props;
    const {
      anchorEl,
      redirect,
      deleteGroupOpen,
      renameGroupOpen,
      groupName,
      deleteMembersOpen,
    } = this.state;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        <IconButton
          aria-label="More"
          aria-owns={open ? "more-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleOpen}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="more-menu"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          getContentAnchorEl={undefined}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
        >
          <MenuItem onClick={this.handleRenameOpen}>Rename Group</MenuItem>
          <MenuItem onClick={this.handleDeleteOpen}>Delete Group</MenuItem>
          <MenuItem
            onClick={this.handleDeleteMembersOpen}
            disabled={selectedUsers.length === 0}
          >
            Remove selected Members
          </MenuItem>
          <MenuItem onClick={this.handleSyncClick}>Sync now</MenuItem>
        </Menu>

        <Dialog open={deleteGroupOpen}>
          <DialogTitle>Delete this group?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deleting this group will stop the synchronisation with other users. The
              contacts will not be deleted.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <LoadingButton onClick={this.handleDeleteGroup}>Delete</LoadingButton>
          </DialogActions>
        </Dialog>

        <Dialog open={renameGroupOpen}>
          <DialogTitle>Rename Group</DialogTitle>
          <DialogContent>
            <TextField
              label="Group Name"
              value={groupName}
              onChange={this.handleChange}
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <LoadingButton onClick={this.handleRenameGroup}>Rename</LoadingButton>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteMembersOpen}>
          <DialogTitle>Delete group members?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deleting members will stop the synchronisation with these users. The
              contacts will not be deleted
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <LoadingButton onClick={this.handleDeleteMembers}>Delete</LoadingButton>
          </DialogActions>
        </Dialog>

        {redirect && <Redirect to="/" />}
      </React.Fragment>
    );
  }

  private handleSyncClick = async () => {
    await api.post(`/group/${this.props.groupId}/sync`, {});
    await this.props.reload();
    this.handleClose();
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ groupName: event.target.value });
  }

  private handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  private handleClose = () => {
    this.setState({
      anchorEl: undefined,
      deleteGroupOpen: false,
      deleteMembersOpen: false,
      renameGroupOpen: false,
    });
  }

  private handleRenameOpen = () => {
    this.handleClose();
    this.setState({ renameGroupOpen: true });
  }

  private handleRenameGroup = async () => {
    await api.put(`/group/${this.props.groupId}`, { name: this.state.groupName });
    await this.props.reload();
    this.handleClose();
  }

  private handleDeleteOpen = () => {
    this.handleClose();
    this.setState({ deleteGroupOpen: true });
  }

  private handleDeleteGroup = async () => {
    await api.delete(`/group/${this.props.groupId}`);
    this.setState({ redirect: true });
  }

  private handleDeleteMembersOpen = () => {
    this.handleClose();
    this.setState({ deleteMembersOpen: true });
  }

  private handleDeleteMembers = async () => {
    await api.post(`/group/${this.props.groupId}/member/delete`, {
      ids: this.props.selectedUsers,
    });
    await this.props.reload();
    this.handleClose();
  }
}

export default withStyles(styles)(GroupMenu);
