import {
  createStyles,
  IconButton,
  SnackbarContent,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import classNames from "classnames";
import * as React from "react";

const variantIcon = {
  error: ErrorIcon,
  info: InfoIcon,
  success: CheckCircleIcon,
  warning: WarningIcon,
};

const styles = (theme: Theme) =>
  createStyles({
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      marginRight: theme.spacing.unit,
      opacity: 0.9,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    message: {
      alignItems: "center",
      display: "flex",
    },
    success: {
      backgroundColor: green[600],
    },
    warning: {
      backgroundColor: amber[700],
    },
  });

type Props = {
  className?: string;
  message: React.ReactNode;
  onClose: ((event: any) => void);
  variant: "success" | "warning" | "error" | "info";
} & WithStyles<typeof styles>;

function MySnackbarContent(props: Props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>}
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

export default withStyles(styles)(MySnackbarContent);
