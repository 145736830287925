import {
  Collapse,
  createStyles,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Add, ExpandLess, ExpandMore, Help, Info, Label } from "@material-ui/icons";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import api from "../../services/api";
import AddNewGroup from "../AddNewGroup";
import DrawerLink from "./DrawerLink";

const styles = (theme: Theme) =>
  createStyles({
    adminList: { minHeight: "56px", overflowY: "auto" },
    drawerPaper: { width: 240 },
    list: { flexGrow: 1, overflowY: "auto", minHeight: "56px", height: "56px" },
    listText: {
      padding: 0,
    },
    toolbar: theme.mixins.toolbar,
  });

type Props = {
  open: boolean;
  isAdmin: boolean;
  handleDrawer: () => void;
} & WithStyles<typeof styles> &
  RouteComponentProps;

interface State {
  addOpen: boolean;
  adminLabels: any[];
  adminOpen: boolean;
  labels: any[];
  selected?: string;
}

class MyDrawer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      addOpen: false,
      adminLabels: [],
      adminOpen: false,
      labels: [],
      selected: undefined,
    };
    this.getLabels();
    if (this.props.isAdmin) {
      this.getAdminLabels();
    }
  }

  public componentDidMount() {
    this.setState({ selected: this.getPath() });
  }

  public componentDidUpdate(prevProps: Props) {
    const path = this.getPath();
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ selected: path });
      if (path === "") {
        this.getLabels();
      }
    }
  }

  public render() {
    const { classes, open } = this.props;
    const iOS = (process as any).browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    return (
      <React.Fragment>
        <Hidden xsDown={true} implementation="js">
          <Drawer
            variant="persistent"
            classes={{ paper: classes.drawerPaper }}
            open={open}
            onClose={this.props.handleDrawer}
          >
            <div className={classes.toolbar} />
            {this.drawerContent()}
          </Drawer>
        </Hidden>
        <Hidden smUp={true} implementation="js">
          <SwipeableDrawer
            disableDiscovery={iOS}
            keepMounted={true}
            variant="temporary"
            open={open}
            classes={{ paper: classes.drawerPaper }}
            onClose={this.props.handleDrawer}
            onOpen={this.props.handleDrawer}
          >
            {this.drawerContent(true)}
          </SwipeableDrawer>
        </Hidden>
      </React.Fragment>
    );
  }

  private getLabels = () => {
    api.get("/label").then((res) => {
      res.sort((a: any, b: any) => {
        if (a.shared && !b.shared) return -1;
        if (!a.shared && b.shared) return 1;
        if (a.formattedName < b.formattedName) return -1;
        if (a.formattedName > b.formattedName) return 1;
        return 0;
      });
      this.setState({ labels: res });
    });
  }

  private getAdminLabels = () => {
    api.get("/group").then((res) => {
      this.setState({ adminLabels: res });
    });
  }

  private handleAddGroupOpen = () => {
    this.setState({ addOpen: true });
  }

  private handleAddGroupClose = () => {
    this.setState({ addOpen: false });
  }

  private handleAdmin = () => {
    this.setState((prevState) => {
      return { adminOpen: !prevState.adminOpen };
    });
  }

  private handleAddGroupSave = () => {
    this.getLabels();
    this.handleAddGroupClose();
  }

  private handleSelect = (id: string) => {
    this.setState({ selected: id });
  }

  private getPath = () => {
    const pathParts = this.props.location.pathname.split("/");
    const path = pathParts[pathParts.length - 1];
    return path;
  }

  private drawerContent = (mobile: boolean = false) => {
    const { classes, isAdmin } = this.props;
    const { labels, selected, addOpen, adminOpen, adminLabels } = this.state;

    const close = () => {
      if (mobile) {
        this.props.handleDrawer();
      }
    };

    return (
      <React.Fragment>
        <List>
          <ListItem button={true} dense={true} onClick={this.handleAddGroupOpen}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText className={classes.listText} primary="Add Group" />
          </ListItem>
          <AddNewGroup
            open={addOpen}
            onClose={() => {
              this.handleAddGroupClose();
            }}
            onSave={() => {
              close();
              this.handleAddGroupSave();
            }}
          />
        </List>
        <Divider />
        <List className={classes.list}>
          {labels.map((label) => {
            return (
              <DrawerLink
                onClick={() => {
                  close();
                  this.handleSelect(label.id);
                }}
                count={label.memberCount}
                groupId={label.id}
                icon={<Label />}
                key={label.id}
                selected={selected === label.id}
                shared={label.shared}
                text={label.formattedName}
              />
            );
          })}
        </List>
        {isAdmin && (
          <React.Fragment>
            <Divider />
            <List className={classes.adminList}>
              <ListItem button={true} dense={true} onClick={this.handleAdmin}>
                <ListItemText className={classes.listText} primary="Admin" />
                {adminOpen ? <ExpandMore /> : <ExpandLess />}
              </ListItem>
              <Collapse in={adminOpen}>
                <List disablePadding={true}>
                  {adminLabels.map((label) => {
                    return (
                      <DrawerLink
                        onClick={() => {
                          close();
                          this.handleSelect(label.id);
                        }}
                        groupId={label.id}
                        icon={<Label />}
                        key={label.id}
                        selected={selected === label.id}
                        shared={true}
                        text={label.name}
                      />
                    );
                  })}
                </List>
              </Collapse>
            </List>
          </React.Fragment>
        )}
        <Divider />
        <List>
          <DrawerLink
            onClick={() => {
              close();
              this.handleSelect("support");
            }}
            selected={selected === "support"}
            to="/support"
            text="Support"
            icon={<Help />}
          />
          <DrawerLink
            onClick={() => {
              close();
              this.handleSelect("about");
            }}
            selected={selected === "about"}
            to="/about"
            text="About"
            icon={<Info />}
          />
        </List>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(MyDrawer));
