import * as React from "react";

// Edit this with customer data
const API_URL = "https://contactify.schmitz-werke.com/api"; // update with customer domain
const LICENSE_URL = "https://contactify.schmitz-werke.com/license"; // update with customer domain
const CLIENT_ID =
  "767308863244-kd8t2upq173pn2gt3qmh140bf5tcsged.apps.googleusercontent.com";
// ----------------------------

const Title = (
  <React.Fragment>
    <b>Schmitz-Werke</b> Contactify
  </React.Fragment>
);

const DEV = JSON.parse(process.env.DEV!) as boolean;
const LOCAL_LICENSE = JSON.parse(process.env.LOCAL_LICENSE!) as boolean;

const APIUrl = DEV ? "http://localhost:3000/api" : API_URL;

const licenseUrl = LOCAL_LICENSE ? "http://localhost:3001/license" : LICENSE_URL;

const clientId = CLIENT_ID;

export { Title, APIUrl, clientId, licenseUrl, DEV };
