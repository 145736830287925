import {
  createStyles,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { PeopleOutline, PersonOutline } from "@material-ui/icons";
import Axios, { CancelTokenSource } from "axios";
import * as React from "react";
import * as Autosuggest from "react-autosuggest";
import api from "../services/api";

function renderInputComponent(inputProps: any) {
  const { classes, ref, inputRef, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: (node: any) => {
          ref(node);
          inputRef(node);
        },
      }}
      {...other}
    />
  );
}

const styles = (_: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 250,
    },
    suggestion: {
      display: "block",
    },
    suggestionsList: {
      listStyleType: "none",
      margin: 0,
      padding: 0,
    },
    typeIcon: {
      marginRight: "5px",
    },
  });

type Props = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect: (userType: string) => void;
  label: string;
  margin: string;
  name: string;
  value: string;
  className: string;
} & WithStyles<typeof styles>;

interface State {
  suggestions: any[];
  loading: boolean;
  anchorEl?: HTMLElement;
}

class CustomAutosuggest extends React.Component<Props, State> {
  private lastRequest?: CancelTokenSource;

  constructor(props: Props) {
    super(props);
    this.state = { suggestions: [], loading: false };
  }

  public handleFetch = async ({ value }: any) => {
    if (this.lastRequest !== undefined) {
      this.lastRequest.cancel();
    }

    const cancelToken = Axios.CancelToken.source();
    this.lastRequest = cancelToken;
    const groupQuery = api.get(`/query?type=group&query=${value}`, {
      cancelToken: cancelToken.token,
    });
    const userQuery = api.get(`/query?type=user&query=${value}`, {
      cancelToken: cancelToken.token,
    });

    const groupResponse = (await groupQuery) || [];
    const userResponse = (await userQuery) || [];

    const groups = groupResponse.map((group: any) => ({ ...group, type: "group" }));
    const users = userResponse.map((user: any) => ({ ...user, type: "user" }));

    const response = [...users, ...groups];

    this.setState({ suggestions: response });
  };

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>, change: any) => {
    event.target.value = change.newValue;
    event.target.name = this.props.name;
    this.props.onChange(event);
  };

  public handleClear = () => {
    this.setState({ suggestions: [] });
  };

  public getSuggestionValue = (suggestion: any) => {
    return suggestion.primaryEmail || suggestion.email;
  };

  public renderSuggestion = (suggestion: any, { isHighlighted }: any) => {
    const { classes } = this.props;
    return (
      <MenuItem selected={isHighlighted} component="div">
        {suggestion.type === "user" ? (
          <PersonOutline className={classes.typeIcon} />
        ) : (
          <PeopleOutline className={classes.typeIcon} />
        )}
        {suggestion.name.fullName || suggestion.name}
      </MenuItem>
    );
  };

  public render() {
    const { classes } = this.props;
    const { suggestions, anchorEl } = this.state;
    const { onSelect, ...props } = this.props;

    const inputProps = {
      ...props,
      inputRef: (node: HTMLElement) => {
        this.setState({ anchorEl: node });
      },
      onChange: this.handleChange,
    };

    return (
      <React.Fragment>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.handleFetch}
          onSuggestionsClearRequested={this.handleClear}
          onSuggestionSelected={(_, { suggestion }) => {
            this.props.onSelect(suggestion.type);
          }}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          theme={{
            suggestion: classes.suggestion,
            suggestionsList: classes.suggestionsList,
          }}
          renderInputComponent={renderInputComponent}
          renderSuggestionsContainer={({ containerProps, children }: any) => (
            <Popper style={{ zIndex: 1300 }} open={true} anchorEl={anchorEl}>
              <Paper
                {...containerProps}
                square={true}
                style={{ width: anchorEl ? anchorEl.clientWidth : null }}
              >
                {children}
              </Paper>
            </Popper>
          )}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CustomAutosuggest);
