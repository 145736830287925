import { createStyles, Snackbar, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import SnackbarContent from "./SnackbarContent";

const styles = createStyles({});

let notifyFn: (level: string, message: string) => void;

type Props = {} & WithStyles<typeof styles>;
interface State {
  open: boolean;
  variant?: "success" | "warning" | "error" | "info";
  message?: string;
}

class MySnackbar extends React.Component<Props, State> {
  public queue: any[];

  constructor(props: Props) {
    super(props);
    this.state = { open: false };
    this.queue = [];
    notifyFn = this.notify;
  }

  public handleExit = () => {
    this.queue.shift();
    this.next();
  }

  public handleClose = (_: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  }

  public handleClickClose = (event: any) => {
    this.handleClose(event, "");
  }

  public next = () => {
    const message = this.queue[0];
    if (message) {
      this.setState({ ...message, open: true });
    }
  }

  public notify = (level: string, message: string) => {
    this.queue.push({ variant: level, message });
    if (this.state.open) {
      this.setState({ open: false });
    } else {
      this.next();
    }
  }

  public render() {
    const { open, message, variant } = this.state;
    return (
      <Snackbar
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        open={open}
        autoHideDuration={4000}
        onClose={this.handleClose}
        onExited={this.handleExit}
      >
        <SnackbarContent
          onClose={this.handleClickClose}
          variant={variant!}
          message={message}
        />
      </Snackbar>
    );
  }
}

export function notify(a: string, b: string) {
  notifyFn(a, b);
}
export default withStyles(styles)(MySnackbar);
