import {
  createStyles,
  IconButton,
  Theme,
  Tooltip,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { NewReleases, Sync, SyncDisabled, SyncProblem } from "@material-ui/icons";
import * as React from "react";

const timeLocales: { [index: string]: string } = {
  prefix: "last synced",
  sufix: "ago",

  day: "a day",
  days: "%d days",
  hour: "about an hour",
  hours: "about %d hours",
  minute: "about a minute",
  minutes: "%d minutes",
  month: "about a month",
  months: "%d months",
  seconds: "less than a minute",
  year: "about a year",
  years: "%d years",
};

const styles = (_: Theme) =>
  createStyles({
    "@keyframes spin": {
      "100%": {
        transform: "rotate(-360deg)",
      },
    },
    "green": {
      color: "green",
    },
    "root": {
      height: "24px",
    },
    "rotate": {
      animation: "spin 2s linear infinite",
    },
  });

type Props = {
  lastSync: string;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  status: string;
} & WithStyles<typeof styles>;

// interface State {}

class SyncIcon extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { classes, status, lastSync } = this.props;

    const icon: any = {
      CRITICAL: <SyncDisabled color="error" />,
      ERROR: <SyncProblem color="error" />,
      NEW: <NewReleases />,
      OK: <Sync className={classes.green} />,
      QUEUE: <Sync className={classes.rotate} />,
      SYNC: <Sync className={classes.rotate} />,
    };

    return (
      <IconButton onClick={this.props.onClick}>
        <div className={classes.root}>
          <Tooltip title={this.getTimeString(new Date(lastSync))}>{icon[status]}</Tooltip>
        </div>
      </IconButton>
    );
  }

  private getTimeString(date: Date) {
    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
    const separator = " ";
    let words = timeLocales.prefix + separator;
    let interval = 0;
    const intervals: { [index: string]: number } = {
      day: seconds / 86400,
      hour: seconds / 3600,
      minute: seconds / 60,
      month: seconds / 2592000,
      year: seconds / 31536000,
    };

    let distance = timeLocales.seconds;

    for (const key in intervals) {
      if (intervals.hasOwnProperty(key)) {
        interval = Math.floor(intervals[key]);

        if (interval > 1) {
          distance = timeLocales[key + "s"];
          break;
        } else if (interval === 1) {
          distance = timeLocales[key];
          break;
        }
      }
    }

    distance = distance.replace(/%d/i, interval.toString());
    words += distance + separator + timeLocales.sufix;

    return words.trim();
  }
}

export default withStyles(styles)(SyncIcon);
