import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  withStyles,
} from "@material-ui/core";
import * as React from "react";
import api from "../../services/api";

const styles = () => createStyles({});

interface Props {
  onClose: () => void;
  changeUser: (user: any) => void;
  user: any;
  onSave: () => void;
  open: boolean;
  width?: any;
  groupId: string;
}

class EditUserDialog extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.changeUser({ ...this.props.user, role: event.target.value });
  }

  public handleMyContacts = (_: React.ChangeEvent<{}>, checked: boolean) => {
    this.props.changeUser({ ...this.props.user, myContacts: checked });
  }

  public handleSave = () => {
    const { groupId, user } = this.props;
    const memberId = user.id;

    api.post(`/group/${groupId}/member/${memberId}`, user).then(this.props.onSave);
  }

  public render() {
    const { open, onClose } = this.props;

    const user = this.props.user ? this.props.user : {};

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Edit Share</DialogTitle>
        <DialogContent style={{ minWidth: "280px" }}>
          <div>
            <TextField
              fullWidth={true}
              label="Email"
              value={user.email}
              disabled={true}
              margin="normal"
            />
          </div>
          <div>
            <TextField
              fullWidth={true}
              label="Role"
              select={true}
              value={user.role}
              onChange={this.handleChange}
              margin="normal"
            >
              <MenuItem value={"READER"}>Reader</MenuItem>
              <MenuItem value={"WRITER"}>Writer</MenuItem>
              <MenuItem value={"OWNER"}>Owner</MenuItem>
            </TextField>
          </div>
          <div>
            <FormControl fullWidth={true} margin={"normal"}>
              <InputLabel filled={true} shrink={true}>
                My Contacts
              </InputLabel>
              <div>
                <FormControlLabel
                  style={{ marginTop: "16px" }}
                  label={
                    user.myContacts ? "Add to my contacts" : "Don't add to my contacts"
                  }
                  control={<Switch checked={user.myContacts} />}
                  onChange={this.handleMyContacts}
                />
              </div>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={this.handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(EditUserDialog);
