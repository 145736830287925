import {
  Button,
  CircularProgress,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";

const styles = (theme: Theme) =>
  createStyles({
    progress: {
      color: theme.palette.secondary.main,
      left: "50%",
      marginLeft: -12,
      marginTop: -12,
      position: "absolute",
      top: "50%",
    },
    wrapper: {
      position: "relative",
    },
  });

type Props = {
  onClick: () => Promise<void>;
} & WithStyles<typeof styles>;

/* tslint:disable-next-line:no-empty-interface */
interface State {
  loading: boolean;
}

class LoadingButton extends React.Component<Props, State> {
  private mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = { loading: false };
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render() {
    const { classes } = this.props;
    const { loading } = this.state;

    return (
      <div className={classes.wrapper}>
        <Button disabled={loading} onClick={this.handleClick}>
          {this.props.children}
        </Button>
        {loading && <CircularProgress className={classes.progress} size={24} />}
      </div>
    );
  }

  private handleClick = async () => {
    this.setState({ loading: true });
    await this.props.onClick();

    if (this.mounted) {
      this.setState({ loading: false });
    }
  }
}

export default withStyles(styles)(LoadingButton);
