import {
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import * as contactifyImage from "../images/contactify.png";
import * as wabionImage from "../images/wabion.png";

const styles = (theme: Theme) =>
  createStyles({
    card: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      maxWidth: "500px",
      padding: theme.spacing.unit * 2,
    },
    cardImage: {
      width: "200px",
    },
    spacer: {
      height: "100px",
    },
    text: {
      marginTop: theme.spacing.unit * 2,
    },
  });

type Props = {} & WithStyles<typeof styles>;

class About extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { classes } = this.props;
    return (
      <Grid container={true} justify="center">
        <div className={classes.card}>
          <img className={classes.cardImage} src={contactifyImage} />
          <Typography className={classes.text} align="justify">
            Wabion Contactify is your business solution to delegate contact lists in Gmail
            to your colleagues or share contact lists to your iOS device.
          </Typography>
          <div className={classes.spacer} />
          <img className={classes.cardImage} src={wabionImage} />
          <Typography className={classes.text} align="justify">
            Wabion is the leading Google Cloud Premier Partner, Expert System Platinum
            Partner and Lucidworks Gold Partner in German-speaking countries like Germany,
            Austria and Switzerland with offices in Germany and Switzerland. We are
            certified and accredited experts for Google G Suite, Google Maps, Google
            Search, Google Chrome and Google Cloud Platform.
          </Typography>
          <Typography className={classes.text} align="justify">
            To find out more, visit our website at{" "}
            <a href="https://www.wabion.com" target="_blank">
              wabion.com
            </a>
          </Typography>
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(About);
