import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { Redirect } from "react-router";
import api from "../services/api";
import LoadingButton from "./LoadingButton";

const styles = () => createStyles({});

interface Props {
  groupName: string;
  labelId?: string;
  onClose: () => void;
  onSave: (id: string) => void;
  open: boolean;
}

type PropsType = Props & WithStyles<typeof styles>;

interface State {
  groupId?: string;
  groupName: string;
  redirect: boolean;
}

class ShareGroupDialog extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);
    this.state = { redirect: false, groupName: props.groupName };
  }

  public render() {
    const { open, onClose } = this.props;
    const { redirect, groupId, groupName } = this.state;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Share Group</DialogTitle>
        <DialogContent>
          <TextField label="Group Name" value={groupName} onChange={this.handleChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton onClick={this.handleSave}>Share</LoadingButton>
        </DialogActions>
        {redirect && <Redirect to={`/group/${groupId}`} />}
      </Dialog>
    );
  }

  private handleSave = async () => {
    const response = await api.post("/group", {
      groupName: this.state.groupName,
      labelId: this.props.labelId,
    });

    this.setState({ redirect: true, groupId: response.id }, () => {
      this.props.onSave(response.id);
    });
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ groupName: event.target.value });
  }
}

export default withStyles(styles)(ShareGroupDialog);
