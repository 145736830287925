import {
  createStyles,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Add, AddCircle, Label, MoreVert, People, Sync } from "@material-ui/icons";
import * as React from "react";

const styles = (theme: Theme) =>
  createStyles({
    block: {
      margin: theme.spacing.unit * 2,
      marginTop: "auto",
      maxWidth: "500px",
    },
    icon: { verticalAlign: "bottom", marginLeft: "0.2em", marginRight: "0.2em" },
    list: { paddingLeft: theme.spacing.unit, marginBottom: theme.spacing.unit * 2 },
  });

type Props = {} & WithStyles<typeof styles>;

class Index extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { classes } = this.props;

    return (
      <Grid container={true} justify="center">
        <Grid className={classes.block} item={true}>
          <Typography variant="h5">How to use Contactify</Typography>

          <ol>
            <Typography variant="body1">
              <li className={classes.list}>
                Click
                <Add className={classes.icon} />
                on the left to create a new shared contacts group. If you want to share an
                existing group just click on it.
              </li>
              <li className={classes.list}>
                Use
                <AddCircle className={classes.icon} />
                to add users from your G Suite domain to your shared group and decide
                which access role (owner, writer, reader) you want to provide them.
              </li>
              <li className={classes.list}>
                The
                <People className={classes.icon} />
                icon indicates if a group is shared with others users or not.
              </li>
              <li className={classes.list}>
                Click
                <Label className={classes.icon} />
                to modify a shared group. Use
                <Sync className={classes.icon} />
                to check the status of the group or to force a complete re-sync to all
                users and choose
                <MoreVert className={classes.icon} />
                to rename or remove a shared group.
              </li>
            </Typography>
          </ol>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Index);
