import Axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { GoogleLoginResponse } from "react-google-login";
import { notify } from "../components/Snackbar";
import { APIUrl, DEV, licenseUrl } from "../config";

class Service {
  public idToken?: string;
  public loginToken?: string;
  public service?: AxiosInstance;
  public user?: GoogleLoginResponse;

  public auth = async (user: GoogleLoginResponse) => {
    this.user = user;
    this.service = Axios.create({ baseURL: APIUrl });
    this.service.interceptors.request.use(this.refreshAuth);
    return this.initAuth();
  };

  private refreshAuth = async (config: AxiosRequestConfig) => {
    await this.initAuth();
    config.headers.Authorization = "Bearer " + this.loginToken;
    return config;
  };

  private initAuth = async () => {
    const auth = this.user!.getAuthResponse();
    if (this.idToken !== auth.id_token) {
      if (DEV) {
        /* tslint:disable-next-line:no-console */
        console.log("id_token", auth.id_token);
      }

      let loginResponse;
      try {
        loginResponse = await Axios.post(`${licenseUrl}/login`, {
          id_token: auth.id_token,
        });
      } catch (error) {
        return false;
      }

      if (DEV) {
        /* tslint:disable-next-line:no-console */
        console.log("Bearer", loginResponse.data.id_token);
      }

      this.idToken = auth.id_token;
      this.loginToken = loginResponse.data.id_token;

      return {
        admin: loginResponse.data.isAdmin,
        interface: loginResponse.data.isInterface,
      };
    }
    return false;
  };

  public get = async (url: string, options?: AxiosRequestConfig) => {
    try {
      const response = await this.service!.get(url, options);
      return response.data;
    } catch (error) {
      if (!Axios.isCancel(error)) {
        notify("error", error.response.data.code + ": " + error.response.data.error);
        throw error;
      }
    }
  };

  public post = async (url: string, data: any) => {
    try {
      const response = await this.service!.post(url, data);
      return response.data;
    } catch (error) {
      if (error.response.data.code) {
        notify("error", error.response.data.code + ": " + error.response.data.error);
      } else {
        notify("error", "500: Internal Server Error");
      }
      // throw error;
    }
  };

  public put = async (url: string, data: any) => {
    try {
      const response = await this.service!.put(url, data);
      return response.data;
    } catch (error) {
      notify("error", error.response.data.code + ": " + error.response.data.error);
      throw error;
    }
  };

  public delete = async (url: string) => {
    try {
      const response = await this.service!.delete(url);
      return response.data;
    } catch (error) {
      notify("error", error.response.data.code + ": " + error.response.data.error);
      throw error;
    }
  };
}

const api = new Service();
export default api;
