import {
  Avatar,
  createStyles,
  Paper,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";
import * as React from "react";
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  GoogleLogout,
} from "react-google-login";
import { clientId } from "../config";
import api from "../services/api";

let logoutFn: () => void;

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.secondary.main,
      margin: theme.spacing.unit,
      marginBottom: theme.spacing.unit * 3,
    },
    layout: {
      display: "block",
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      width: "auto",
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        marginLeft: "auto",
        marginRight: "auto",
        width: 400,
      },
    },
    login: {
      marginTop: theme.spacing.unit * 3,
    },
    paper: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing.unit * 8,
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing
        .unit * 3}px`,
    },
  });

type Props = {
  stateUp: (state: object) => void;
  notify: (variant: string, message: string) => void;
} & WithStyles<typeof styles>;

class Login extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    logoutFn = this.logout;
  }

  public handleSuccess = async (
    user: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => {
    user = user as GoogleLoginResponse;
    const service = api;
    const auth = await service.auth(user);
    if (auth === false) {
      this.props.notify("error", "Not authorized");
      this.props.stateUp({ loggedIn: false });
    } else if (auth.interface || auth.admin) {
      this.props.stateUp({
        loggedIn: user.isSignedIn(),
        user,
        service,
        isAdmin: auth.admin,
      });
    } else {
      this.props.notify("error", "You are not authorized to use the interface");
    }
  };

  public handleFailue = (error: any) => {
    this.props.notify("error", error.details);
  };

  public handleLogout = () => {
    this.props.stateUp({ loggedIn: false, user: undefined });
    this.props.notify("success", "Logged Out");
  };

  public logout = () => {
    const gLogout = new GoogleLogout({ onLogoutSuccess: this.handleLogout, clientId });
    gLogout.signOut();
  };

  public render() {
    const { classes } = this.props;
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <GoogleLogin
            clientId={clientId}
            buttonText="Login with Google"
            responseType="id_token permission"
            onSuccess={this.handleSuccess}
            onFailure={this.handleFailue}
            isSignedIn={true}
          />
        </Paper>
      </main>
    );
  }
}

export function logout() {
  logoutFn();
}
export default withStyles(styles)(Login);
