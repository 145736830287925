import {
  AppBar,
  createStyles,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import * as React from "react";
import { Link } from "react-router-dom";
import { logout } from "../pages/Login";

const styles = (theme: Theme) =>
  createStyles({
    accountImage: { width: "1.5em", height: "1.5em", borderRadius: "100%" },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    title: { width: "fit-content", textDecoration: "unset" },
    titleContainer: { flexGrow: 1 },
  });

type Props = {
  profile: any;
  handleDrawer: () => void;
  title: React.ReactNode;
} & WithStyles<typeof styles>;

interface State {
  anchorEl?: HTMLElement;
}

class MainAppBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: undefined,
    };
  }

  public handleMenu = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  public handleClose = () => {
    this.setState({ anchorEl: undefined });
  }

  public render() {
    const { classes, profile, handleDrawer, title } = this.props;
    const { anchorEl } = this.state;

    const open = Boolean(anchorEl);
    return (
      <AppBar color="primary" className={classes.appBar}>
        <Toolbar>
          {handleDrawer && (
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={handleDrawer}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div className={classes.titleContainer}>
            <Typography
              className={classes.title}
              // to="/"
              // component={Link as any}
              component={({ innerRef, ...props }) => <Link {...props} to="/" />}
              variant="h6"
              color="inherit"
            >
              {title}
            </Typography>
          </div>
          <IconButton
            aria-owns={open ? "menu-appbar" : undefined}
            aria-haspopup="true"
            onClick={this.handleMenu}
            color="inherit"
          >
            {profile.imageUrl ? (
              <img className={classes.accountImage} src={profile.imageUrl} />
            ) : (
              <AccountCircle className={classes.accountImage} />
            )}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleClose}
            getContentAnchorEl={undefined}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
          >
            <MenuItem disabled={true}>{profile.name}</MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(MainAppBar);
