import {
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
  withWidth,
} from "@material-ui/core";
import { indigo, pink } from "@material-ui/core/colors";
import { WithWidth } from "@material-ui/core/withWidth";
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import AppBar from "./components/AppBar";
import Drawer from "./components/Drawer";
import Snackbar, { notify } from "./components/Snackbar";
import { Title } from "./config";
import Login from "./pages/Login";
import Main from "./pages/Main";

const theme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: pink,
  },
  typography: { useNextVariants: true },
});

type Props = {
  compiler: string;
  framework: string;
} & WithWidth;

interface State {
  drawerOpen: boolean;
  isAdmin: boolean;
  isInterface: boolean;
  loggedIn: boolean;
  user?: any;
}

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      drawerOpen: this.props.width !== "xs",
      isAdmin: false,
      isInterface: false,
      loggedIn: false,
      user: undefined,
    };
  }

  public stateUp = (s: any) => {
    this.setState(s);
  };

  public handleDrawer = () => {
    this.setState((prevState) => {
      return { drawerOpen: !prevState.drawerOpen };
    });
  };

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.width !== this.props.width) {
      this.setState({ drawerOpen: this.props.width !== "xs" });
    }
  }

  public render() {
    const { loggedIn, drawerOpen, user, isAdmin } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Snackbar />
        {!loggedIn && <Login stateUp={this.stateUp} notify={notify} />}
        {loggedIn && (
          <BrowserRouter>
            <React.Fragment>
              <AppBar
                title={Title}
                profile={user!.profileObj}
                handleDrawer={this.handleDrawer}
              />
              <Drawer
                handleDrawer={this.handleDrawer}
                open={drawerOpen}
                isAdmin={isAdmin}
              />
              <Main drawerOpen={drawerOpen} user={user} isAdmin={isAdmin} />
            </React.Fragment>
          </BrowserRouter>
        )}
      </MuiThemeProvider>
    );
  }
}

export default withWidth()(App);
