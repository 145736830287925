import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { Route } from "react-router-dom";
import About from "./About";
import Group from "./Group";
import Index from "./Index";
import Support from "./Support";

const styles = (theme: Theme) =>
  createStyles({
    content: {
      marginLeft: 0,
      transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
    },
    contentShift: {
      marginLeft: 240,
      transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.easeOut,
      }),
      [theme.breakpoints.down(600)]: {
        marginLeft: 0,
      },
    },
    layout: {
      // [theme.breakpoints.up(1000 + theme.spacing.unit * 2 * 2)]: {
      //   width: 1000,
      //   marginLeft: "auto",
      //   marginRight: "auto"
      // }
    },
    layoutBase: {
      marginTop: 64 + theme.spacing.unit * 2,
      width: "auto",
      // margin: theme.spacing.unit * 2,
      [theme.breakpoints.down(600)]: {
        marginTop: 48 + theme.spacing.unit * 2,
      },
    },
    layoutShift: {
      // [theme.breakpoints.up(1240 + theme.spacing.unit * 2 * 2)]: {
      //   width: 1000,
      //   marginLeft: "auto",
      //   marginRight: "auto"
      // }
    },
  });

type Props = {
  drawerOpen: boolean;
  user: any;
  isAdmin: boolean;
} & WithStyles<typeof styles>;

class Main extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: props.user,
    };
  }

  public render() {
    const { classes, drawerOpen, isAdmin } = this.props;
    return (
      <React.Fragment>
        <div
          className={classNames(classes.content, {
            [classes.contentShift]: drawerOpen,
          })}
        >
          <main
            className={classNames(classes.layoutBase, {
              [classes.layoutShift]: drawerOpen,
              [classes.layout]: !drawerOpen,
            })}
          >
            {/* <Route exact={true} path="/group/:id" component={Group} /> */}
            <Route
              exact={true}
              path="/group/:id"
              render={(routeProps) => <Group {...routeProps} isAdmin={isAdmin}></Group>}
            />
            <Route exact={true} path="/support" component={Support} />
            <Route exact={true} path="/about" component={About} />
            <Route exact={true} path="/" component={Index} />
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Main);
